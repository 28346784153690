<template>
  <div class="manage-project-sales-legal-process">
    <div class="container fluid">
      <legal-client-table
        :getClientsAPI="lawyerAPI.getClients"
        detailRouteName="ManageProjectLegalProcessDetail"
      ></legal-client-table>
    </div>
  </div>
</template>

<script>
import LegalClientTable from "@/components/GlobalComponents/Shared/Lawyer/LegalClientTable";
import { projectLegalProcess as lawyerAPI } from "@/api";

export default {
  components: {
    LegalClientTable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      lawyerAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>

